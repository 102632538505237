import type {
	PrefetchDependenciesContext,
	PrefetchDependency,
	PrefetchedUserData,
	PrefetchUserContext,
	PrefetchUserContextData,
} from 'src/shared/prefetch';

import { resolvePrefetchableComponent } from './resolveComponent';

export async function prefetchComponentUserData(
	comp: PrefetchDependency,
	options: PrefetchUserContext,
): Promise<PrefetchUserContextData> {
	const component = await resolvePrefetchableComponent(comp);

	if (!component.prefetchName) {
		return undefined;
	}

	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const userDataPromise: Promise<PrefetchedUserData> = component.prefetchUserData
		? component.prefetchUserData(options)
		: Promise.resolve(undefined);
	// making sure that a rejection will not be unhandled (which would crash the app)
	// this can happen in rare cases because of timing issues
	// if the promise throws, it will still be thrown by this function since we await userDataPromise (without the catch)
	// eslint-disable-next-line promise/prefer-await-to-then
	userDataPromise.catch(() => {});
	const getUserData = async () => userDataPromise;

	const prefetchDependenciesOptions: PrefetchDependenciesContext = {
		l10n: options.l10n,
		path: options.path,
		params: options.params,
		query: options.query,
		session: options.session,
		getUserData,
	};
	const dependencies = await Promise.resolve(component.prefetchDependencies?.(prefetchDependenciesOptions) || []);
	const depData = await Promise.all([
		getUserData().then((data) => (data ? { [component.prefetchName]: data } : {})),
		...dependencies.map(async (dep) => prefetchComponentUserData(dep, options)),
	]);

	const combinedData = depData.reduce<PrefetchUserContextData>((acc, dep) => {
		const combined = {
			...acc,
			...dep,
		};
		// if the sum of keys is not the same as the combined object's # of keys,
		// it means that dep has keys that were already in acc, which is not supposed to happen
		// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument, @typescript-eslint/consistent-type-assertions
		if (Object.keys(acc as any).length + Object.keys(dep || {}).length !== Object.keys(combined).length) {
			throw new Error('Prefetched data overrides other prefetched data');
		}
		return combined;
	}, {});

	return combinedData;
}
